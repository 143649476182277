<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Add New Inventory Category</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col md="12" cols="12">
              <v-text-field
                color="secondary"
                v-model="inventoryCategory.name"
                label="Name"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="saveInventoryCategory">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'

export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      inventoryCategory: {
        name,
      },
      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    saveInventoryCategory: function() {
      this.$store
        .dispatch(`inventoryCategory/storeNewInventoryCategories`, this.inventoryCategory)
        .then(response => {
          window.mitt.emit('loadInventoryCategories', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetForm: function() {
      this.inventoryCategory = {}
    },
    close() {
      this.resetForm()
      this.$emit('close-dialog')
    },
  },
}
</script>

<style></style>
