<template>
  <v-card>
    <v-toolbar height="45" flat color="primary" dark>
      <v-toolbar-title class="white--text"
        ><v-icon class="white--text" left>{{ icons.mdiTools }}</v-icon> Edit Part</v-toolbar-title
      >
    </v-toolbar>
    <v-tabs>
      <v-tab>
        <v-icon left>
          {{ icons.mdiPencilOutline }}
        </v-icon>
        Edit Part
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiCardAccountDetails }}</v-icon>
        Pricing
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="inventory.name" color="secondary" label="Name" dense outlined></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.description"
                    color="secondary"
                    label="Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.part_number"
                    dense
                    label="Part Number"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.bin_location"
                    dense
                    label="Bin Location"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="inventory.inv_category_id"
                    :append-icon="icons.mdiDotsHorizontalCircleOutline"
                    @click:append="addnewInventoryCategory"
                    :items="inventoryCategories"
                    item-value="inv_category_id"
                    item-text="name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Inventory Category"
                    :prepend-inner-icon="icons.mdiChartBox"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="inventory.vendor_id"
                    :append-icon="icons.mdiDotsHorizontalCircleOutline"
                    :items="vendors"
                    item-value="vendor_id"
                    item-text="vendor_name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Vendor"
                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                    @click:append="$router.push({ name: 'addnewvendor' })"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.quantity"
                    dense
                    label="Quantity"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.qty_on_hand"
                    dense
                    label="Quantity on Hand"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="inventory.pricing_id"
                    :append-icon="icons.mdiDotsHorizontalCircleOutline"
                    :items="pricings"
                    item-value="pricing_id"
                    item-text="name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Pricing Matrix"
                    @click:append="$router.push({ name: 'pricingMatrix' })"
                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                    single-line
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.cost"
                    color="secondary"
                    prefix="$"
                    label="Cost"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.retail_price"
                    dense
                    label="Retail Price"
                    outlined
                    color="secondary"
                    prefix="$"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="inventory.markup" class="mt-n1" mandatory row>
                    <h3 class="mr-5">Markup</h3>
                    <v-radio
                      v-for="option in options"
                      :key="option.id"
                      :label="option.text"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :append-icon="icons.mdiPercentOutline"
                    v-model="inventory.markup"
                    dense
                    label="Markup %"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="inventory.margin" class="mt-n1" mandatory row>
                    <h3 class="mr-5">Margin</h3>
                    <v-radio
                      v-for="option in options"
                      :key="option.id"
                      :label="option.text"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :append-icon="icons.mdiPercentOutline"
                    v-model="inventory.margin"
                    dense
                    label="Margin %"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-switch class="mt-n4" flat label="Should this be taxed?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer receive a discount?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor rate override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor matrix override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a pricing matrix override?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded @click.prevent="updatePartInventory">
              <v-icon right light>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'inventory' })">
              <v-icon right light>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <add-inventorycategory
      :dialog.sync="showNewInventoryCategoryDialog"
      @open-dialog="showNewInventoryCategoryDialog = true"
      @close-dialog="showNewInventoryCategoryDialog = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AddNewDialog from '@/views/inventorycategory/AddNewInventoryCategory.vue'
import EditDialog from '@/views/inventorycategory/EditInventoryCategory.vue'
import moment from 'moment'

import {
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiAccountSupervisor,
  mdiCar,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiBell,
  mdiCalendarAccount,
  mdiClose,
  mdiArchiveArrowDown,
  mdiTools,
  mdiCarInfo,
  mdiChartBox,
  mdiAccountMultipleOutline,
  mdiPercentOutline,
  mdiPencilOutline,
  mdiDotsHorizontalCircleOutline,
} from '@mdi/js'

export default {
  components: {
    'add-inventorycategory': AddNewDialog,
    'edit-inventorycategory': EditDialog,
  },

  created: function () {
    this.getInventoryCategories()
    this.getVendors()
    this.getPricings()
    this.getInventories()
  },
  data() {
    return {
      inventoryCategories: [],
      vendors: [],
      pricings: [],
      pricingItems: [],
      inventory: {},
      showNewInventoryCategoryDialog: false,
      showEditInventoryCategoryDialog: false,
      options: [
        {
          id: 1,
          text: '15%',
          value: '15.00',
        },
        {
          id: 2,
          text: '25%',
          value: '25.00',
        },
        {
          id: 3,
          text: '35%',
          value: '35.00',
        },
        {
          id: 4,
          text: '50%',
          value: '50.00',
        },
      ],

      icons: {
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiAccountSupervisor,
        mdiCar,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiBell,
        mdiCalendarAccount,
        mdiClose,
        mdiArchiveArrowDown,
        mdiTools,
        mdiCarInfo,
        mdiChartBox,
        mdiAccountMultipleOutline,
        mdiPercentOutline,
        mdiPencilOutline,
        mdiDotsHorizontalCircleOutline,
      },
    }
  },
  methods: {
    getInventories: function () {
      this.$store
        .dispatch('inventory/editInventoryItems', {
          inv_id: this.$route.params.id,
        })
        .then(response => {
          this.inventory = this.fetchInventoryItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    getInventoryCategories: function () {
      this.$store
        .dispatch('inventoryCategory/fetchInventoryCategories')
        .then(response => {
          this.inventoryCategories = this.fetchInventoryCategories
        })
        .catch(err => {
          console.log(err)
        })
    },
    getVendors: function () {
      this.$store
        .dispatch('vendor/fetchVendors')
        .then(response => {
          this.vendors = this.fetchVendors
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPricings: function () {
      this.$store
        .dispatch('pricing/fetchPricings')
        .then(response => {
          this.pricings = this.fetchPricings
        })
        .catch(err => {
          console.log(err)
        })
    },
    addnewInventoryCategory: function () {
      this.showNewInventoryCategoryDialog = true
    },

    updatePartInventory: function () {
      this.$store
        .dispatch(`inventory/updateInventoryItem`, {
          inv_id: this.$route.params.id,
          data: this.inventory,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'inventory' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchInventoryCategories: 'inventoryCategory/fetchInventoryCategories',
      fetchVendors: 'vendor/fetchVendors',
      fetchPricings: 'pricing/fetchPricings',
      fetchPricingItems: 'inventory/fetchPricingItems',
      fetchInventoryItems: 'inventory/fetchInventoryItems',
    }),
    selectedOptionType() {
      if (!this.inventory.markup) {
        return ''
      }
      return this.options.find(o => o.id === this.inventory.markup).value
    },
  },
}
</script>

<style>
</style>